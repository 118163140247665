<template>
  <div>
    <b-modal
      ref="warranty-benefit-policy-bill-modal"
      hide-footer
      :title="title"
      border-0
      centered
      id="warranty-benefit-policy-bill-modal"
      size="lg"
    >
      <div class="d-block text-center">
        <!-- Table session -->
        <b-table :items="processedListProduct" :fields="fields" bordered hover>
          <template #head(checkbox)>
            <b-form-checkbox
              v-model="allSelected"
              @change="onSelectAllRows"
            ></b-form-checkbox>
          </template>
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template #cell(checkbox)="row">
            <b-form-checkbox
              v-model="row.item.selected"
              @change="onChangeChecked()"
            ></b-form-checkbox>
          </template>
          <template #cell(productName)="row">
            <span>{{ row.item.productName }}</span>
          </template>
          <template #cell(imeiNo)="row">
            <div class="d-flex align-items-center justify-content-center">
              <span v-if="productImeiOptions.length === 1">{{
                productImeiOptions[0].imeiNo
              }}</span>
              <b-form v-else>
                <treeselect
                  v-model="row.item.imeiNo"
                  :options="productImeiOptions"
                  :multiple="false"
                  :normalizer="normalizer"
                  :match-keys="['label', 'productName']"
                  placeholder="Chọn IMEI in phiếu"
                  noResultsText="Không tìm thấy IMEI"
                  :limit="6"
                >
                </treeselect>
              </b-form>
            </div>
          </template>
          <template #cell(description)="row">
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <b-form-group
                v-if="row.item.productType === PRODUCT_TYPE.PRODUCT_SERVICE"
                class="mb-0"
              >
                <b-form-select
                  size="sm"
                  v-model="row.item.warrantyBenefitPolicyId"
                  :options="warrantyBenfitPolicyOptions"
                  value-field="id"
                  text-field="typeName"
                  class="select-style"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">
                      -- Chọn loại chính sách bảo hành --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
              <div>
                <b-link
                  v-if="row.item.warrantyBenefitPolicyId"
                  @click="
                    onClickViewDetailWarrantyPolicy(
                      row.item.warrantyBenefitPolicyId,
                    )
                  "
                  class="mt-2"
                >
                  Xem chi tiết
                </b-link>
              </div>
            </div>
          </template>
          <template #cell(actions)="row">
            <div class="d-flex justify-content-center">
              <span
                style="color: #3f4254; font-size: 10px"
                type="button"
                @click="deleteItemPrint(row.item)"
              >
                <i
                  class="flaticon2-rubbish-bin-delete-button icon-size text-danger"
                ></i>
              </span>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->
      </div>
      <div class="mt-3 d-flex justify-content-end">
        <b-button
          class="mr-2 btn-width-25"
          variant="secondary"
          @click="hideModal"
          >Huỷ</b-button
        >
        <b-button
          variant="primary"
          class="btn-width-25"
          @click="confirmPrintWarrantyBenefitPolicy()"
          >Xác nhận</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { PRODUCT_TYPE } from '@/utils/enum';
import { cmdUrl } from '@/utils/apiUrl';
import { makeToastFaile } from '@/utils/common';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import uniqBy from 'lodash/uniqBy';

export default {
  props: {
    checkedItem: {
      type: Object,
    },
  },
  data() {
    return {
      title: 'Chọn gói bảo hành và IMEI trước khi in',
      allSelected: false,
      fields: [
        {
          key: 'checkbox',
          label: '',
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'productName',
          label: 'Gói bảo hành',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '32%' },
        },
        {
          key: 'imeiNo',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '33%' },
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '5%' },
        },
      ],
      localListProduct: [],
      warrantyBenfitPolicyOptions: [],
      seletedType: null,
      PRODUCT_TYPE: PRODUCT_TYPE,
      productImeiOptions: [],
    };
  },
  components: {
    Treeselect,
  },
  created() {
    this.fetchListWarrantyBenefitPolicy();
  },
  computed: {
    processedListProduct() {
      return this.localListProduct;
    },
  },
  watch: {
    checkedItem(newVal) {
      this.handleWarrantyPackageData(newVal);
    },
  },
  methods: {
    normalizer(node) {
      return {
        id: node.imeiNo,
        label: node.imeiNo,
        productName: node.productName,
      };
    },
    onClickViewDetailWarrantyPolicy(warrantyBenefitPolicyId) {
      const routeData = this.$router.resolve({
        name: 'upsert-warranty-benefit-policy',
        query: { id: warrantyBenefitPolicyId, action: 'view' },
      });
      window.open(routeData.href, '_blank');
    },
    onChangeChecked() {
      const isAnyUnchecked = this.localListProduct.some(
        (item) => !item.selected,
      );
      this.allSelected = !isAnyUnchecked;
    },
    deleteItemPrint(item) {
      this.localListProduct = this.localListProduct.filter(
        (productPrint) => productPrint.id !== item.id,
      );
    },
    async handleWarrantyPackageData({ customerId, billItems }) {
      const productServices = [];
      for (const product of billItems) {
        if (
          product.productType === PRODUCT_TYPE.PRODUCT_IMEI ||
          product.imeiId
        ) {
          this.productImeiOptions.push(product);
        }
        if (
          product.productType === PRODUCT_TYPE.PRODUCT_SERVICE ||
          product.productName.includes('BH')
        ) {
          productServices.push({
            ...product,
            productType: PRODUCT_TYPE.PRODUCT_SERVICE,
            warrantyBenefitPolicyId: null,
            selected: true,
          });
        }
      }
      this.allSelected = true;
      this.localListProduct = productServices;
      const productImeiOptionLength = this.productImeiOptions.length;
      const productServicesLength = productServices.length;

      // Nếu có 1 sản phẩm IMEI & 1 GBH thì tự động add IMEI vào GBH
      if (productServicesLength === 1) {
        this.localListProduct = this.localListProduct.map((item) => {
          const imeiNo =
            productImeiOptionLength === 1
              ? this.productImeiOptions[0].imeiNo
              : null;
          return {
            ...item,
            imeiNo,
          };
        });
      } else {
        this.localListProduct = this.localListProduct.map((item, index) => {
          const imeiNo =
            productImeiOptionLength === 1
              ? this.productImeiOptions[0].imeiNo
              : this.productImeiOptions[index]?.imeiNo;
          return {
            ...item,
            imeiNo,
          };
        });
      }
      //Trường hợp mua riêng GBH không có IMEI, gợi ý lấy imei của khách trong các bill trước
      if (productImeiOptionLength === 0) {
        const imeiOptions = await this.fetchImeiPurchaseByCustomer(customerId);
        if (!imeiOptions.length) {
          makeToastFaile('Không tìm thấy IMEI khách hàng đã mua trước đó!');
          this.hideModal();
        }
        this.productImeiOptions = imeiOptions;
      }
    },
    async fetchImeiPurchaseByCustomer(customerId) {
      const {
        data: { data },
      } = await ApiService.get(`customer/${customerId}/purchase/imei-no`);
      return uniqBy(data, 'imeiNo');
    },
    async fetchListWarrantyBenefitPolicy() {
      const {
        data: { data },
      } = await ApiService.get(cmdUrl.warrantyBenefitPolicy.root);
      this.warrantyBenfitPolicyOptions = data.result;
    },
    showModal() {
      this.$refs['warranty-benefit-policy-bill-modal'].show();
    },
    hideModal() {
      this.$refs['warranty-benefit-policy-bill-modal'].hide();
    },
    onSelectAllRows() {
      this.localListProduct = this.localListProduct.map((product) => {
        return {
          ...product,
          selected: this.allSelected,
        };
      });
    },
    confirmPrintWarrantyBenefitPolicy() {
      const listProductChecked = [];
      for (const product of this.processedListProduct) {
        if (!product.selected) {
          continue;
        }
        const warrantyBenefitInfo = this.warrantyBenfitPolicyOptions.find(
          (item) => item.id === product.warrantyBenefitPolicyId,
        );
        if (!warrantyBenefitInfo) {
          return makeToastFaile(
            `Chọn chính sách bảo hành cho ${product.productName}`,
          );
        }
        if (!product.imeiNo) {
          return makeToastFaile(
            `Chọn IMEI bảo hành cho ${product.productName}`,
          );
        }
        listProductChecked.push({
          ...product,
          warrantyBenefitInfo,
        });
      }
      if (!listProductChecked.length) {
        return makeToastFaile(
          'Không có gói bảo hành nào được chọn trước khi in!',
        );
      }
      this.$emit('checkedProductPrint', listProductChecked);
      this.hideModal();
    },
  },
};
</script>

<style scoped>
.btn-width-25 {
  width: 25%;
}
</style>
